import React, { useEffect, useContext,useState } from 'react';
import { UserContext } from '../../UserContext';
import { axios } from '../../config';
import { Route, Link, Switch } from "react-router-dom";
import M from 'materialize-css'
import { NewPacking, NewDepto,EditDepto, Departamentos, NewInspeccion, missingBoxes, NotFoundBoxes, NewLAbels, NewOrder, OrderStatus, StatusPacking, Missing, StockRacks, ProductToEvaluate, ProductToSort, ProductInGranel, Defects, BigRacks, Catalogo, Movimientos, Pedidos, Ventas } from './almacen'
import { Recoleccion, EmailConfig, NewProduct, EditProduct, MiTienda, AvisoPrivacidad, PoliticaCompra, PagoEnvio, Faq, Glosario, Cupones , Carrusel, Bolsa, ContactoWeb, Boletin, Entrega} from './e-commerce'
import Wizard from './wizard';

const Dashboard = () => {
	const { user, setUser, setAuth, configs, setConfigs } = useContext(UserContext);
	const [loader, setLoader] = useState(true)
	
	useEffect(()=>{

		axios.get('/api/user')
		.then((response)=>{
			setUser(response.data)	
			//setLoader(false)
			M.AutoInit()
		})
		.catch((e)=>console.log('error',e))

		axios.get('/api/config')
		.then((response)=>{
			setConfigs(response.data)	
			if(response.data.config_store.wizard_config)setLoader(false)
			//M.Collapsible.init(document.querySelector('.collapsible'))
			M.AutoInit()
		})
		.catch((e)=>console.log('error',e))
	},[])

	const _salir=()=>{
		console.log('salir')
		axios.get('/api/logout')
		.then(r=>{
			setAuth(false)
			window.location.reload()
		})
	}
	
	const hola=()=>{
		return (
			<div className="card-panel">
				<h4>BIENVENIDO {user.name.toUpperCase()}</h4>
			</div>
		)
	}

	const _production_mode = (checked) => {
		axios.post('/api/production_mode',{production_mode:checked})
		.then(r=>{
			setConfigs({...configs, production_mode:checked})
		})
	}

	if(loader) return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)

	if(!configs.config_store.wizard_config.installed) 
		return (
			<Wizard />
		)

	return (
		<div className="row">
			<div className="col s3" id="menu" style={{width: '250px',}}>
				<ul id="slide-out" className="collapsible sidenav collapsible sidenav-fixed" style={{width: '250px',}}>
					<li className='center' style={{padding:'20px'}}>
						<img width='150' src={axios.defaults.baseURL+'/logo.png'} alt='recurso' style={{objectFit:'cover',borderRadius:'50%',widht:150, height:150, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}/>
						<div style={{height:20, fontSize:14, lineHeight:'14px'}}>Sitio</div>
						<div className='switch' style={{width:200, fontSize:12}}>
							<label>
								Desactivado
								<input type="checkbox" checked={configs.production_mode} onChange={(e)=>_production_mode(e.target.checked)}/>
							<span className="lever"></span>
								Activado
							</label>
						</div>
					</li>
					<li>
						<div className="collapsible-header menu-title"
						style={{borderBottom:'solid 1px!important #000000!important', fontWeight:'800!important #000000!important'}}> <i className="material-icons">library_books</i> Inventarios</div>
						<div className="collapsible-body">
						<ul>
						<li><Link to="/productInGranel" className="waves-effect">Granel</Link></li>
							<li><Link to="/productToEvaluate" className="waves-effect">Calidad/Producto Por Evaluar</Link></li>
							<li><Link to="/productToSort" className="waves-effect">Calidad</Link></li>
							<li><Link to="/stockRacks" className="waves-effect">Racks</Link></li>
							<li><Link to="/bigracks" className="waves-effect">Bigracks</Link></li>
							<li><Link to="/catalogo" className="waves-effect"><i className="material-icons">loyalty</i>Mi catálogo</Link></li>
							<li><Link to="/Departamentos" className="waves-effect"><i className="material-icons">chrome_reader_mode</i>Mis departamentos</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"><i className="material-icons">home</i>Recepción</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/newPacking" className="waves-effect">Registrar Contenedor</Link></li>
							<li><Link to="/missingBoxes" className="waves-effect">Reportes de cajas Faltantes</Link></li>
							<li><Link to="/statusPacking" className="waves-effect">Status Contenedores</Link></li>
							<li><Link to="/missing" className="waves-effect">Recibir Cajas(Metodo Manual)</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"><i className="material-icons">search</i>Inspección</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/newInspection" className="waves-effect">Solicitar Inspección de Calidad</Link></li>
							<li><Link to="/notFoundBoxes" className="waves-effect">Reportes de cajas no Encontradas</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"> <i className="material-icons">local_atm</i> Pedidos</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/newOrder" className="waves-effect">Solicitar Pedido</Link></li>
							<li><Link to="/orderStatus" className="waves-effect">Status de Pedidos</Link></li>
							<li><Link to="/ventasonline" className="waves-effect"><i className="material-icons">shopping_cart</i>Listos a enviar</Link></li>
							<li><Link to="/listapedidos" className="waves-effect"><i className="material-icons">storage</i>Lista de pedidos</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"><i className="material-icons">bug_report</i>Desviaciones</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/defects" className="waves-effect">No Aprobados por Calidad</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"><i className="material-icons">code</i>Movimientos</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/movimientos" className="waves-effect">General</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"><i className="material-icons">assignment</i> Altas</div>
						<div className="collapsible-body">
							<ul>
								<li><Link to="/NewDepto" className="waves-effect"><i className="material-icons">art_track</i>Alta de departamento</Link></li>
								<li><Link to="/altaproducto" className="waves-effect"><i className="material-icons">flash_on</i>Alta de productos</Link></li>
							</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"><i className="material-icons">settings_applications</i> Configuración</div>
						<div className="collapsible-body">
							<ul>
								{/*<li><a href="#!" className="subheader">Configuración</a></li>*/}
								<li><Link to="/tienda-configs" className="waves-effect"><i className="material-icons">store</i>Mi tienda</Link></li>
								<li><Link to="/cupones" className="waves-effect"><i className="material-icons">loyalty</i>Cupones</Link></li>
								<li><Link to="/carrusel" className="waves-effect"><i className="material-icons">image</i>Carrusel</Link></li>
								<li><Link to="/email-config" className="waves-effect"><i className="material-icons">mail</i>Configuración de correo</Link></li>
								<li><Link to="/recolecciones" className="waves-effect"><i className="material-icons">local_shipping</i>Recolecciones</Link></li>
							</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"><i className="material-icons">work</i> Politicas</div>
						<div className="collapsible-body">
							<ul>
								<li><Link to="/aviso-privacidad" className="waves-effect"><i className="material-icons">account_balance</i>Aviso de privacidad</Link></li>
								<li><Link to="/politica-compra" className="waves-effect"><i className="material-icons">assignment_turned_in</i>Política de compras</Link></li>
								<li><Link to="/pagos-y-envios" className="waves-effect"><i className="material-icons">send</i>Pagos y envíos</Link></li>
								
							</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"><i className="material-icons">help</i> Ayuda</div>
						<div className="collapsible-body">
							<ul>
								<li><Link to="/faq" className="waves-effect"><i className="material-icons">feedback</i>FAQs</Link></li>
								<li><Link to="/glosario" className="waves-effect"><i className="material-icons">extension</i>Glosario</Link></li>
							</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header"><i className="material-icons">announcement</i> Forms</div>
						<div className="collapsible-body">
							<ul>
								<li><Link to="/bolsa" className="waves-effect"><i className="material-icons">business_center</i>Bolsa de trabajo</Link></li>
								<li><Link to="/contacto" className="waves-effect"><i className="material-icons">cloud</i>Contacto Web</Link></li>
								<li><Link to="/boletin" className="waves-effect"><i className="material-icons">comment</i>Suscritos boletín</Link></li>
							</ul>
						</div>
					</li>
					<li>
						<a href="#!" onClick={_salir} className="waves-effect" style={{padding:0, margin:0, display:'block', alignItems:'center'}}>
                                                        <i className="material-icons" style={{padding:0, margin:'0 10px 0 0',}}>exit_to_app</i>Salir
                                                </a>
					</li>
				</ul>
				<a href="#!" data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a>
			</div>
			<div className="col s9">
			<Switch>
				<Route path="/" exact component={hola} />
				<Route path="/newPacking" component={NewPacking} />
				<Route path="/newInspection" component={NewInspeccion} />
				<Route path="/missingBoxes" component={missingBoxes} />
				<Route path="/notFoundBoxes" component={NotFoundBoxes} />
				{/*<Route path="/print" component={Print} />*/}
				<Route path="/newLAbels" component={NewLAbels} />
				<Route path="/newOrder" component={NewOrder} />
				<Route path="/orderStatus" component={OrderStatus} />
				<Route path="/statusPacking" component={StatusPacking} />
				<Route path="/missing" component={Missing} />
				<Route path="/stockRacks" component={StockRacks} />
				<Route path="/productToEvaluate" component={ProductToEvaluate} />
				<Route path="/productToSort" component={ProductToSort} />
				<Route path="/productInGranel" component={ProductInGranel} />
				<Route path="/defects" component={Defects} />
				<Route path="/bigracks" component={BigRacks} />
				<Route path="/catalogo" component={Catalogo} exact/>
				{/*departamentos*/}
				<Route path="/NewDepto" component={NewDepto} exact/>
				<Route path="/departamentos/departamento/:departamento_id" component={EditDepto} exact/>
				<Route path="/Departamentos" component={Departamentos} exact/>
				{/*pedidos*/}
				<Route path="/ventasonline" component={Ventas} />
				{/*movimientos*/}
				<Route path="/movimientos" component={Movimientos} />
				{/*movimientos*/}
				<Route path="/listapedidos" component={Pedidos} />
				{/*e-commerce*/}
				<Route path="/altaproducto" component={NewProduct} />
				<Route path="/catalogo/producto/:catalogo_id" component={EditProduct} />
				<Route path="/tienda-configs" component={MiTienda} />
				<Route path="/email-config" component={EmailConfig} />
				<Route path="/aviso-privacidad" component={AvisoPrivacidad} />
				<Route path="/politica-compra" component={PoliticaCompra} />
				<Route path="/pagos-y-envios" component={PagoEnvio} />
				<Route path="/faq" component={Faq} />
				<Route path="/glosario" component={Glosario} />
				<Route path="/cupones" component={Cupones} />
				<Route path="/carrusel" component={Carrusel} />
				<Route path="/recolecciones" component={Recoleccion} />
				<Route path="/bolsa" component={Bolsa} />
				<Route path="/contacto" component={ContactoWeb} />
				<Route path="/boletin" component={Boletin} />
				<Route path="/entrega/:pedido_id" component={Entrega} />
			</Switch>
			</div>
		</div>
	)
}

export default Dashboard
