import React, { useState } from 'react'
import { axios } from '../../../../config'

const NewInspection = () => {

	const [data, setData]=useState({})

	const _packing = e => {
		var reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);
		reader.onload = () => {
			setData({...data,packing:reader.result})
		};
	} 

	const _push = () => {
		axios.post('/api/newInpection',{excel:data.packing})
		.then(r=>{
			alert('ha solicitado una nueva inspeccion para Calidad')
			if(r.data.length>0)
			alert('Cajas no encontradas en inventario de bodega '+r.data.join(','))
		})
		.catch(r=>alert(r))
	}
	return (
		<div className="container">
			<div className="row">
				<div className="card-panel">
					<div className="row">
						<div className="input-field">
							<div className="file-field input-field">
								<div className="btn">
									<span>Packing</span>
									<input onChange={_packing} type="file" />
								</div>
								<div className="file-path-wrapper">
									<input className="file-path validate" type="text" />
								</div>
							</div>
						</div>
						<button onClick={_push} className="btn waves-effect waves-light right" type="submit" name="action">Subir
							<i className="material-icons right">send</i>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
export default NewInspection
