import React, { useState, useEffect } from 'react'
import { axios } from '../../../../config'

const StockRacks = () => {

	const [porAcomodar, setPorAcomodar]=useState([])
	const [search, setSearch]=useState([])

	useEffect(()=>{
		axios.get('/api/getToSortOut')
		.then(r=>{
			setPorAcomodar(r.data)
                 })
                 .catch(r=>alert(r))
	},[])
	
	const _rackToExcel = () => {
		const busqueda=porAcomodar.filter(s=>s.codigo.includes(search))
		axios.post('/api/toSortToExcel',{productos:busqueda})
		.then(r=>{
			var a = document.createElement("a");
			a.href = r.data.file;
			a.download = r.data.name;
			document.body.appendChild(a);
			a.click();
			a.remove();
		})
		.catch(r=>alert(r))
	}

	return (
			<div className="row">
				<div className="card-panel">
				<h4>Por Acomodar/Aprobado por calidad</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field">
								<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required />
								<label className="label-icon" htmlFor="search"><i className="material-icons">search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				<a href="#!" className="waves-effect waves-light btn right" onClick={_rackToExcel}>Exportar</a>
				<table className="highlight">
					<thead>
						<tr>
							<th>#</th>
							<th>Código</th>
							<th>Nombre</th>
							<th>Piezas Por Display</th>
							<th>Cantidad de Displays</th>
							<th>Total de productos/piezas</th>
						</tr>
					</thead>
					<tbody>
					{porAcomodar.filter(i=>i.codigo.includes(search)).map((m,i)=>
						<tr key={i}>
							<td>{i+1}</td>
							<td>{m.codigo}</td>
							<td>{m.nombre}</td>
							<td>{m.display}</td>
							<td>{Math.floor(m.piezas/m.display)}</td>
							<td>{m.piezas}</td>
						</tr>
					)}
					</tbody>
				</table>
				</div>
			</div>
	)
}
export default StockRacks
