import React, { useEffect, useState } from 'react';
import M from 'materialize-css'
import { axios } from '../../../../config'

const Faq = () => {

	const [data, setData] = useState({pregunta:'', respuesta:''});
	const [faqs, setFaqs] = useState([])
	const [faq, setFaq] = useState({pregunta:'', respuesta:''})

	useEffect(()=>{
		M.Modal.init(document.querySelectorAll('.modal'), {});
		axios.get('/api/faq')
		.then(r=>{
			setFaqs(r.data)
		})
		.catch(r=>alert(r))
	},[])

	const _pushStore = (e) => {
		e.preventDefault()
		axios.post('/api/faq', data)
		.then(r=>{
			setFaqs([...faqs, r.data])
			setData({pregunta:'', respuesta:''})
			M.toast({html:'faq cargada!'})
		})
		.catch(r=>alert(r))
	}

	const _pushEdit = (e) => {
		e.preventDefault()
		axios.put('/api/faq', faq)
		.then(r=>{
			M.toast({html:'faq actualizada!'})
			setFaqs(faqs.map(f=>f.id===r.data.id?r.data:f))
		})
		.catch(r=>alert(r))
	}

	const _pushDelete = (obj) => {
		if(window.confirm(`¿Estas seguro que deseas borrar? : ${obj.pregunta}`))
		axios.delete(`/api/faq/${obj.id}`)
		.then(r=>{
			M.toast({html:'faq eliminada!'})
			setFaqs(faqs.filter(f=>f.id!==obj.id))
		})
		.catch(r=>alert(r))
	}

	const _showEdit = (obj) => {
		const instance = M.Modal.getInstance(document.querySelector('#modal-edit'));
		setFaq(obj)
		instance.open();

		setTimeout(()=>{
			M.updateTextFields()
			M.textareaAutoResize(document.querySelector('#respuesta2'));
		},1000)

	}

	return (
		<>
		<div id='modal1' className='modal'>
			<div className='modal-content'>
				<h4>Nuevo Faq</h4>
				<form onSubmit={_pushStore}>
					<div className='row'>
						<div className='input-field col s12'>
							<input value={data.pregunta} onChange={(e)=>setData({...data, pregunta:e.target.value})}id='pregunta' type='text' className='validate' required/>
							<label htmlFor='pregunta'>Pregunta</label>
						</div>
						<div className='input-field col s12'>
							<textarea value={data.respuesta} onChange={(e)=>setData({...data, respuesta:e.target.value})} id='respuesta' className='materialize-textarea' />
							<label htmlFor='respuesta'>Respuesta</label>
						</div>
					</div>
					<button className='modal-close btn waves-effect waves-light'>Agregar Nueva FAQ</button>
				</form>
			</div>
		</div>
		<div id='modal-edit' className='modal'>
			<div className='modal-content'>
				<h4>Editar Faq</h4>
				<form onSubmit={_pushEdit}>
					<div className='row'>
						<div className='input-field col s12'>
							<input value={faq.pregunta} onChange={(e)=>setFaq({...faq, pregunta:e.target.value})} id='pregunta2' type='text' className='validate' required/>
							<label htmlFor='pregunta2'>Pregunta</label>
						</div>
						<div className='input-field col s12'>
							<textarea value={faq.respuesta} onChange={(e)=>setFaq({...faq, respuesta:e.target.value})} id='respuesta2' className='materialize-textarea' />
							<label htmlFor='respuesta2'>Respuesta</label>
						</div>
					</div>
					<button className='modal-close btn waves-effect waves-light'>Guardar</button>
				</form>
			</div>
		</div>
		<div className='card-panel'>
			<h4>FAQ</h4>
			<a className='waves-effect waves-light btn modal-trigger right' href='#modal1'>Agregar</a>
			<table>
				<thead>
					<tr>
						<th style={{width:'5%'}}>#</th>
						<th style={{width:'28%'}}>Pregunta</th>
						<th style={{width:'52%'}}>Respuesta</th>
						<th style={{width:'10%'}}>Opciones</th>
					</tr>
				</thead>
				<tbody>
				{faqs.map((f,i)=>
					<tr key={i}>
						<td style={{textAlign:'center', width:'5%'}}>{i+1}</td>
						<td style={{lineHeight:'1', whiteSpace:'pre-line', width:'28%'}}>{f.pregunta}</td>
						<td style={{lineHeight:'1', whiteSpace:'normal', width:'52%'}}><p>{f.respuesta}</p></td>
						<td style={{width:'10%'}}>
							<div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'4px 0'}}><a onClick={()=>_showEdit(f)} className='waves-effect waves-light btn modal-trigger' href='#!'>Editar</a></div>
							<div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'4px 0'}}><a onClick={()=>_pushDelete(f)} className='waves-effect waves-light btn' href='#!'>Eliminar</a></div>
						</td>
					</tr>
				)}
				</tbody>
			</table>

		</div>
		</>
	)
}

export default Faq
