import React,{ useState, useEffect } from 'react'
import M from 'materialize-css'
import { axios } from '../../../../config'
import Loading from '../../../loading'

const NewPacking = () => {

	const [data, setData] = useState({})
	const [loader, setLoader] = useState(false)

	useEffect(()=>{
		M.updateTextFields()
		M.Datepicker.init(document.querySelector('#fechallegada'), {format:'yyyy-mm-dd',autoClose:false,i18n:{
			cancel:'cancelar',
			months:['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
			monthsShort: [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic' ],
			weekdays: [ 'Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado' ],
			weekdaysShort: [ 'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab' ],
			weekdaysAbbrev:	['D','L','M','M','J','V','S'],
		},
			onClose:()=>{
			setData({...data,fechallegada:document.querySelector('#fechallegada').value})

			},
		});
		M.Timepicker.init(document.querySelectorAll('#horallegada'), {
			twelveHour:false,
			onCloseEnd:()=>{
				setData({...data,horallegada:document.querySelector('#horallegada').value})
			}
		});
	},[data])

	const _packing=(e)=>{
		var reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);
		reader.onload = () => {
			setData({...data,packing:reader.result})
		};
	}

	const _push=()=>{
		setLoader(true)
		axios.post('/api/newPacking',data)
		.then(r=>{
			setLoader(false)
			alert('Packing registrado')
		})
		.catch(r=>alert(r))
	}

	return (
		<div className="container">
			<div className="row">
				<div className="card-panel">
					<h4>New PackingList</h4>
					<a href={axios.defaults.baseURL+'/plantillas/plantillaPacking.xlsx'} className='btn waves-effect waves-light right'>Descargar Plantilla</a>
					{loader ? <Loading />:
					<>
					<div className="row">
						<div className="input-field">
							<input onChange={(e)=>setData({...data,contenedor:e.target.value})} value={data.contenedor||''} id="contenedor" type="text" />
							<label htmlFor="contenedor">Numero de Contenedor</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field">
							<input id="fechallegada" type="text" />
							<label htmlFor="fechallegada">Fecha de llegada</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field">
							<input type="text" id="horallegada" />
							<label htmlFor="horallegada">Hora de llegada</label>
						</div>
					</div>
					<div className="row">
						<div className="input-field">
							<div className="file-field input-field">
								<div className="btn">
									<span>Packing</span>
									<input onChange={_packing} type="file" />
								</div>
								<div className="file-path-wrapper">
									<input className="file-path validate" type="text" />
								</div>
							</div>
						</div>
						<button onClick={_push} className="btn waves-effect waves-light right" type="submit" name="action">Subir
							<i className="material-icons right">send</i>
						</button>
					</div>
					</>
					}
				</div>
			</div>
		</div>
	)
}

export default NewPacking
