import React, { useEffect, useState } from 'react';
import M from 'materialize-css'
import { axios } from '../../../../config'

const Boletin = () => {

	const [boletins, setBoletins] = useState([])

	useEffect(()=>{
		axios.get('/api/boletin')
		.then(r=>{
			setBoletins(r.data)
		})
		.catch(r=>alert(r))
	},[])

	return (
		<>
		<div className='card-panel'>
			<h4>Boletín</h4>
			<p>Correos suscritos al boletín</p>
			<table>
				<thead>
					<tr>
						<th>#</th>
						<th>Correo</th>
						<th>Fecha suscripción</th>
					</tr>
				</thead>
				<tbody>
				{boletins.map((b,i)=>
					<tr key={i}>
						<td>{i+1}</td>
						<td>{b.correo}</td>
						<td>{new Date(b.created_at).toLocaleString()}</td>
					</tr>
				)}
				</tbody>
			</table>

		</div>
		</>
	)
}

export default Boletin
