import React, { useState, useEffect } from 'react'
import { axios } from '../../../../config'
import { Link } from "react-router-dom"
import M from 'materialize-css'

const Departamentos = () => {
	const [view, setSwich] = useState(false)
	const [departamentos, setDepartamentos] = useState([])
	const [search, setSearch] = useState('')
	const [typing, setTyping] = useState(0)

	useEffect(()=>{
		axios.get('/api/departamento')
		.then(r=>{
			setDepartamentos(r.data)
			M.Tabs.init(document.querySelectorAll('.tabs'),{})
		})
		.catch(r=>alert(r))
	},[])

	const _changeView = () =>{
		setSwich(!view)
	}


	/*
	   *
	   * Catalogo obj, String type = 'increment'|'decrement'|'value'
	   */
	const _updateStock = (obj, type) => {
		const newObj = {...obj, stock:type==='increment'?obj.stock+1:(type==='decrement'?obj.stock-1:type)}

		if(newObj.stock>=0){
			const updateDepartamentos = departamentos.map((c) =>c.id===obj.id?newObj:c)
			setDepartamentos(updateDepartamentos)

			clearTimeout(typing)
			setTyping(setTimeout(() => {
				axios.post('/api/updateStock',{codigo:newObj.codigo, stock:newObj.stock})
				.then(r=>{
					M.toast({html:'Actualizado!'})
				})
			.catch(r=>alert(r))
			},1000))
		}
	}
	
	/*
	   *
	   * Catalogo obj
	   */
	const _updateDepartamentos = (obj,checked) =>{
		const newDepartamentos = departamentos.map(c=>c.id===obj.id?{...obj,visible:checked}:c)
		setDepartamentos(newDepartamentos)
		axios.post('/api/updateVisible',{id:obj.id,visible:checked})
		.then((r) => {
			M.toast({html:'Actualizado!'})
		})
		.catch(r=>alert(r))	
	}

	/**
	  *
	  * @param {Object} departamento
	  */
	const _delete = (departamento) => {
		if(window.confirm('seguro que desea eliminar el departamento?'))
		axios.delete(`/api/departamento/${departamento.id}`)
		.then((r) => {
			setDepartamentos(departamentos.filter(d=>d.id!==departamento.id))
			M.toast({html:'Departamento eliminado!'})
		})
		.catch(r=>alert(r))
	}

	return (
		<div className="col s12" style={{marginTop:"50px",}}>
			<ul id="tabs-swipe-demo" className="tabs ul-tabs">
				<li className="tab col s2"><a className="active" href="#depatamentos-tab">Departamentos</a></li>
			</ul>
			<div id="depatamentos-tab" className='card-panel'>
				<div className="row m-p-0">
				
				{!view &&
					<div className="col m12 m-p-0">  
						<i className="small material-icons right" 
						onClick={_changeView}
						style={{cursor:'pointer'}}>tock</i> 
					</div>
				}
				{view &&
					<div className="col m12 m-p-0"> 
						
						<i className="small material-icons right" 
						onClick={_changeView}
						style={{cursor:'pointer'}}>apps</i> 
					</div>
				}
				</div>
				
				<h4>Lista de departamentos</h4>
				
			{!view?
				<div className="row">
					<table id="table-departamentos">
						<thead>
						<tr>
							<th></th>
							
							<th style={{width:'20%', textAlign:'center'}}>Departamento</th>
							{/*<th style={{width:'8%', textAlign:'center'}}>Visible</th>*/}
							<th>Descripción</th>
							<th>No. Categorías</th>
							<th>No. Productos</th>
							<th style={{width:'10%', textAlign:'center'}}>Opciones</th>
						</tr>
						</thead>

						<tbody>
						{departamentos.filter(c=>c.departamento.toLowerCase().includes(search.toLowerCase()) || c.codigo.includes(search)||c.categoria.toLowerCase().includes(search.toLowerCase()) || c.departamento.toLowerCase().includes(search.toLowerCase())).slice(0,50).map(c=>
							<tr key={c.id}>
								<td style={{width:'100px', height:'100px'}}>
									<img style={{maxWidth:'100%', maxHeight:'100%'}} src={c.img} />
								</td>
								<td style={{width:'20%', textAlign:'center'}}> {c.departamento} </td>
									{/*<td style={{width:'8%'}}>
									<div className="switch" style={{margin:'0 auto', textAlign:'center'}}>
										<label>
											<input type="checkbox" checked={c.visible} onChange={(e)=>_updateDepartamentos(c,e.target.checked)}/>
											<span className="lever"></span>
										</label>
									</div> 
								</td>*/}
								<td> {c.descripcion} </td>
								<td style={{textAlign:'center'}}> {c.categorias.length} </td>
								<td style={{textAlign:'center'}}> {c.catalogo_count} </td>
								<td style={{width:'6%', margin:'0 auto', textAlign:'center'}}>
									<div className="col s6">
										<Link to={`/departamentos/departamento/${c.id}`} className='black-text'>
											<i className="material-icons">edit</i>
										</Link>
									</div>
									<div className="col s6">
										<a href='#!' onClick={()=>_delete(c)} className='black-text'>
											<i className="material-icons">delete</i>
										</a>
									</div>
								</td>
							</tr>
						)}
						</tbody>
					</table>
				</div>
				:
				<div className="row">
				{departamentos.filter(c=>c.departamento.toLowerCase().includes(search.toLowerCase()) || c.codigo.includes(search)||c.categoria.toLowerCase().includes(search.toLowerCase()) || c.departamento.toLowerCase().includes(search.toLowerCase())).slice(0,50).map(c=>
					<div key={c.id} className="col m6">
						<div className="card-panel" style={{position:'relative'}}>
							<div style={{position:'absolute', right:10, top:10}}>
								<div className="col s6">
									<Link to={`/departamentos/departamento/${c.id}`} className='black-text'>
										<i className="material-icons">edit</i>
									</Link>
								</div>
								<div className="col s6">
									<a href='#!' onClick={()=>_delete(c)} className='black-text'>
										<i className="material-icons">delete</i>
									</a>
								</div>
							</div>
						<div className='row'>
							<div className="col m5" id="slider2" style={{width:'240px', height:'240px'}}>
								<img style={{maxWidth:'100%', maxHeight:'100%'}} src={c.img} />
							</div>
								<div className="col m7">
									<div style={{height:160, overflowY:'auto'}}>
										<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Departamento:</b> {c.departamento}</div>
										<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>No. Categorías:</b> {c.catalogo_count} </div>
										<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>No. Productos:</b> {c.categorias.length} </div>
										<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Descripción:</b> {c.descripcion}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			
				</div>
			}
			</div>
		</div>
	)
}

export default Departamentos
