import React, { useState, useEffect } from 'react';
import { axios } from '../../../../config'
import JoditEditor from "jodit-react";
import Loading from '../../../loading'

const PoliticaCompra = () => {

	const [data, setData] = useState({politica_compra:''})
	const [loader, setLoader] = useState(true)

	useEffect(()=>{
		axios.get('/api/politicacompra')
		.then(r=>{
			setData(r.data)
			setLoader(false)
		})
		.catch(r=>alert(r))
	},[])

	const _push = () => {

		const dataF = new FormData()
		dataF.append('politica_compra', data.politica_compra)

		axios.post('/api/politicacompra', dataF)
		.then(r=>{
			alert('Las políticas de compra se han guardado satisfactoriamente')
		})
		.catch(r=>alert(r))
	}

	return(
		<div className='card-panel'>
			<h4>Política de compras</h4>
			{loader?
			<Loading />:
			<div className="row">
				<div className="input-field col s12">
					<JoditEditor value={data.politica_compra} config={{ readonly: false,askBeforePasteHTML:false }} tabIndex={1} onBlur={newContent => setData({...data, politica_compra:newContent})} />
				</div>
			</div>
			}
			<a href='#!' onClick={_push} className='btn waves-effect waves-light'>Guardar</a>
		</div>
	)
}

export default PoliticaCompra
