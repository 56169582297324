import React, { useState, useEffect } from 'react'
import { axios } from '../../../../config'

const StockRacks = () => {

	const [stock, setStock]=useState([{codigo:''}])
	const [search, setSearch]=useState([])

	useEffect(()=>{
		axios.get('/api/stockRacks')
		.then(r=>{
			setStock(r.data)
		})
		.catch(r=>alert(r))
	},[])
	
	const _rackToExcel = () => {
		const busqueda=stock.filter(s=>s.codigo.includes(search)||s.departamento.toLowerCase().includes(search)||s.rack.includes(search))
		axios.post('/api/rackToExcel',busqueda)
		.then(r=>{
			var a = document.createElement("a");
			a.href = r.data.file;
			a.download = r.data.name;
			document.body.appendChild(a);
			a.click();
			a.remove();
		})
		.catch(r=>alert(r))
	}

	return (
			<div className="card-panel">
			<div className="row">
			<h4>RACKS</h4>
			<nav>
				<div className="nav-wrapper">
					<form>
						<div className="input-field">
							<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required />
							<label className="label-icon" htmlFor="search"><i className="material-icons">search</i></label>
							<i className="material-icons">close</i>
						</div>
					</form>
				</div>
			</nav>
			<a href="#!" className="waves-effect waves-light btn right" onClick={_rackToExcel}>Exportar</a>
			</div>

			<div className="table-wrapper">
			<table className="highlight">
				<thead>
					<tr>
						<th>#</th>
						<th>Código</th>
						<th>Nombre</th>
						<th>Departamento</th>
						<th>Categoría</th>
						<th>Piezas por Display</th>
						<th>Cantidad de Displays</th>
						<th>Piezas</th>
						<th>Rack</th>
					</tr>
				</thead>
				<tbody>
				{stock.filter(s=>s.codigo.includes(search)||s.departamento.toLowerCase().includes(search)||s.rack.includes(search)).map((m,i)=>
					<tr key={i}>
						<td>{i+1}</td>
						<td>{m.codigo}</td>
						<td>{m.nombre_del_producto}</td>
						<td>{m.departamento}</td>
						<td>{m.categoria}</td>
						<td>{m.display}</td>
						<td>{m.cantidadDisplays}</td>
						<td>{m.piezas}</td>
						<td>{m.rack}</td>
					</tr>
				)}
				</tbody>
			</table>
			</div>
			</div>
	)
}
export default StockRacks
