import React, { useState, useEffect } from 'react'
import { axios } from '../../../../config'
import M from 'materialize-css'

const BigRack = () => {

	const [search, setSearch] = useState('')
	const [bigracks, setBigracks] = useState([])
	
	useEffect(()=>{
		axios.get('/api/getBigRack')
		.then(r=>setBigracks(r.data))
		.catch(r=>alert(r))
	},[])

	const _toExcel = () => {
		axios.get('/api/bigRackToExcel')
		.then(r=>{
			var a = document.createElement("a");
			a.href = r.data.file;
			a.download = r.data.name;
			document.body.appendChild(a);
			a.click();
			a.remove();
		})
		.catch(r=>alert(r))
	}

	const _solicitar = (obj) => {
		axios.post('/api/solicitarBigrack', {rack: obj.rack, tarima_id: obj.tarima_id, caja_id: obj.caja_id})
		.then(r=>{
			  M.toast({html: `Caja ${obj.caja_id} solicitada`})
		})
		.catch(r=>alert(r))

	}

	return (
		<div className="card-panel">
			<div className="row">
			<h4>BIGRACKS</h4>
			<nav>
				<div className="nav-wrapper">
					<form>
						<div className="input-field">
							<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required placeholder='Caja, Código de producto'/>
							<label className="label-icon" htmlFor="search"><i className="material-icons">search</i></label>
							<i className="material-icons">close</i>
						</div>
					</form>
				</div>
			</nav>
			<a href="#!" className="waves-effect waves-light btn right" onClick={_toExcel}>Exportar</a>
			</div>

			<div className="table-wrapper">
			<table className="highlight">
				<thead>
					<tr>
						<th>#</th>
						<th>Bigrack</th>
						<th>Tarima</th>
						<th>Caja</th>
						<th>Contenido</th>
					</tr>
				</thead>
				<tbody>
				{bigracks.filter(b=>b.caja_id.includes(search)||b.productos.some(b=>b.codigo.includes(search))).map((b,i)=>
					<tr key={i}>
						<td>{i+1}</td>
						<td>{b.rack}</td>
						<td>{b.tarima_id}</td>
						<td>
						<div style={{fontSize: 40}}>
						{b.caja_id}
						</div>
						<a href="#!" onClick={()=>_solicitar(b)} className='btn waves waves-effect waves-light'>Solicitar</a>
						</td>
						<td>
							<ul className="collection" style={{height: 100, overflowY: 'auto'}}>
						{b.productos.map(p=>({codigo:p.codigo,nombre: p.nombre_del_producto})).map((p,i)=>
								<li key={i} className="collection-item avatar">
									<img src="https://as2.ftcdn.net/jpg/02/87/91/37/500_F_287913769_mOCOSX7ZNo9nNX7mb6VILAALUVQzC8Wo.jpg" alt="" className="circle" />
									<span className="title">Title</span>
									<p>{p.codigo} <br />
									{p.nombre}
									</p>
								</li>
						)}
							</ul>
				</td>
					</tr>
				)}
				</tbody>
			</table>
			</div>
		</div>
	)
}

export default BigRack
