import React, { useEffect, useState } from 'react';
import { axios } from '../../../../config'
import M from 'materialize-css'
import FileUploadWithPreview from 'file-upload-with-preview';
import JoditEditor from "jodit-react";
import AsyncCreatableSelect from 'react-select/async-creatable';

const NewProduct = () => {
	const [showBotton, setShowBotton] = useState(false)
	const [data, setData] = useState({codigo:'', nombre:'', modelo:'',talla:'', descripcion:'',descripcion_general:'', peso_bruto:'', peso_neto:'', precio:'', minimo_compra:'', departamento_id:'', stock:1})
	const [deptInput, setDepsInput] = useState({departamento:'', descripcion:''})
	const [categorias, setCategorias] = useState([])
	const [catSelect, setCatSelect] = useState([])
	const [departamentos, setDepartamentos] = useState([])
	const [catInput, setCatInput] = useState('')
	const [fotos, setFotos] = useState([])
	const [loader, setLoader] = useState(false)
	const [search, setSearch] = useState('')
	const [foto, setFoto] = useState(false)
	const [inputModelos, setInputModelos] = useState([])

	useEffect(() => {

		axios.get('/api/departamento')
		.then((r) => {
			setDepartamentos(r.data)
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
		.catch(r=>alert(r))
		new FileUploadWithPreview('uploadId');
	}, [])

	const _catSelect = (obj) => {
		setCategorias(categorias.filter((c)=>c.id!==obj.id))
		setCatSelect([...catSelect, obj])
	}

	const _catSelectDelete = (obj) => {
		setCatSelect(catSelect.filter((c)=>c.id!==obj.id))
		setCategorias([...categorias, obj])
	}

	const _pushCat = () => {
		axios.post('/api/categoria',{categoria:catInput, departamento_id:data.departamento_id})
		.then((r) => {
			setCatSelect([...catSelect, r.data])
			setCatInput('')
			M.toast({html: 'Categoria cargada!'})
		})
		.catch(r=>alert(r))
	}
	const _pushDeps = () => {
		if(foto){
			const dataDep = new FormData()
			dataDep.append('data', JSON.stringify(deptInput))
			dataDep.append('files', foto)
			axios.post('/api/departamento', dataDep)
			.then((r) => {
				setDepartamentos([...departamentos, r.data])
				setDepsInput({departamento:'', descripcion:''})
				M.FormSelect.init(document.querySelectorAll('select'), {});
				M.toast({html: 'Departamento cargado!'})
				setShowBotton(false)
				setData({...data, departamento_id:r.data.id})
			})
			.catch(r=>alert(r))
		}else{
			alert('Elija una foto departamental')
		}
	}
	
	const _pushData = (e) => {
		e.preventDefault()
		if(catSelect.length===0){
			alert('selecciona categorias')
		}
		else{
			const dataF = new FormData()
			dataF.append('data', JSON.stringify({...data, categorias: catSelect.map((c) =>c.id ), modelo:data.modelo.value}))
			fotos.forEach(f => dataF.append('files[]', f));
	
			setLoader(true)
			axios.post('/api/catalogo',dataF)
			.then((r) => {
				setLoader(false)
				setData({codigo:'', nombre:'', modelo:'', talla:'', descripcion:'', descripcion_general:'', peso_bruto:'', peso_neto:'',precio:'', minimo_compra:'', departamento_id:'', stock:1})
				M.toast({html: 'Agregado al catálogo!'})
				M.FormSelect.init(document.querySelectorAll('select'), {});
				setFotos([])
				new FileUploadWithPreview('uploadId');
				setCategorias([...categorias, ...catSelect])
				setCatSelect([])
			})
			.catch(r=>{
				if(r.response.status===400)
				alert(r.response.data.message)
				else alert(r)
				setLoader(false)
			})
		}
	}

	const _dosDecimales = (n) => {
		let t=n.toString();
		let regex=/(\d*.\d{0,2})/;
		if(t.length===0) return 0;
		return t.match(regex)[0];
	}

	const promiseOptions = (search) => {

		console.log('hola')
		console.log(search)

		return new Promise((resolve, reject) => {

		if(search.length==1){
			axios.post('/api/modelos', {search})
			.then(r=>{
				setInputModelos(r.data)
				resolve(r.data)
				console.log('paso1')
			})
			.catch(r=>alert(r))
		}else{
			setTimeout(() => {
				resolve(inputModelos.filter(c=>c.label.includes(search.toUpperCase())));
			}, 300);
		}

		});

	}

	if(loader)
	return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)

	return(
		<div className='card-panel'>
			<h4>Alta  de producto</h4>
			<form onSubmit={_pushData}>
				<div className='row'>
					<div className='col' onSubmit={(e)=>e.preventDefault()}>
						<div className='col m6'>
							<div className='input-field col s12'>
								<input name='codigo' value={data.codigo} onChange={(e) => setData({...data, codigo:e.target.value})} id='codigo' type='text' required/>
								<label htmlFor='codigo'>Código</label>
							</div>
							<div className='input-field col s12'>
								<input name='nombre' value={data.nombre} onChange={(e) => setData({...data, nombre:e.target.value})} id='nombre' type='text' required/>
								<label htmlFor='nombre'>Nombre</label>
							</div>
							<label>Modelo(Opcional)</label>
							<div style={{fontSize:10, color:'red'}}>Nota: el modelo es importante para casos con productos de unico modelo, por ejemplo productos unicos con tallas diferentes.</div>
							<div className='input-field col s12'>
								<AsyncCreatableSelect formatCreateLabel={(e)=>'Nuevo Modelo: '+e.toUpperCase()} value={data.modelo} onChange={(e)=>setData({...data, modelo:{value:e.value.toUpperCase(), label:e.label.toUpperCase()}})} defaultOptions loadOptions={promiseOptions} loadingMessage={()=>'Cargando info...'}/>
							</div>
							<div className='input-field col s12'>
								<input name='talla' value={data.talla} onChange={(e) => setData({...data, talla:e.target.value})} id='talla' type='text' />
								<label htmlFor='talla'>Talla (opcional)</label>
							</div>
						</div>
						<div className='col m6'>
							<div className='input-field col s12'>
								Descripción corta  <br></br>
								<textarea name='descripcion' value={data.descripcion} onChange={(e) => setData({...data, descripcion:e.target.value})}  id='textarea1' className='materialize-textarea' style={{minHeight:200, border:'solid 1px grey'}} required />
								
							</div>
						</div>
					</div>
					<div className='col s12'>
						<div className='input-field col s12'>
							Información del producto: <br></br>
							<JoditEditor value={data.descripcion_general} tabIndex={1} config={{ readonly: false,askBeforePasteHTML:false}} onBlur={newContent => setData({...data, descripcion_general:newContent})} />
						</div>
					</div>
					<div className='row'>
						<div className='col m4'>
							<div className='input-field col s12'>
								Existencias <br></br>
								<input name='stock' min="0" value={data.stock} onChange={(e) => setData({...data, stock:e.target.value.replace(/\D/g, '')})}  id='stock' type='number' required/>
							</div>
						</div>
						<div className='col m4'>
							<div className='input-field col s12'>
								Peso bruto en gramos.  <span style={{color:'#000000', fontSize:'12px', fontWeight:900}}> Nota:Decimales no son permitidos </span> <br></br>
								<input name='peso_bruto' min="0" value={data.peso_bruto} onChange={(e) => setData({...data, peso_bruto:e.target.value.replace(/\D/g, '')})}  id='peso-bruto' type='number' required/>
							</div>
						</div>
						<div className='col m4'>
							<div className='input-field col s12'>
								Peso neto en gramos.  <span style={{color:'#000000', fontSize:'12px', fontWeight:900}}> Nota:Decimales no son permitidos </span> <br></br>
								<input name='peso_neto' min="0" value={parseInt(data.peso_neto)} onChange={(e) => setData({...data, peso_neto:e.target.value.replace(/\D/g, '')})} id='peso-neto' type='number' required/>
							</div>
						</div>
						<div className='col m6'>
							<div className='input-field col s12'>
								<input name='precio' min="0" value={data.precio} onChange={(e) => setData({...data, precio:_dosDecimales(e.target.value)})} id='precio' type='number' required/>
								<label htmlFor='precio'>Precio</label>
							</div>
						</div>
						<div className='col m6'>
							<div className='input-field col s12'>
								<input name='minimo_compra' min="0" value={data.minimo_compra} onChange={(e) => setData({...data, minimo_compra:e.target.value.replace(/\D/g, '')})} id='minimo_compra' type='number' required/>
								<label htmlFor='minimo_compra'>Minimo de piezas</label>
							</div>
						</div>
						<div className='col m6' style={{border:'solid yellow!important'}}>
							<div className='input-fieldi col s12'>
								<select name='departamento' value={data.departamento_id} onChange={(e)=>{setData({...data, departamento_id:e.target.value});setCategorias(departamentos.find(d=>String(d.id)===e.target.value).categorias)}} required>
									<option value='' disabled>Seleccionar departamento</option>
									{departamentos.map((d,i) => <option key={i} value={d.id}>{d.departamento}</option>)}
								</select>
								<label>Departamento</label>
							</div>
							<div className='input-fieldi col s12'>	
								<div className='col m12'>
									<a onClick={() => setShowBotton(true)} className='btn waves-effect waves-light'>Nuevo departamento</a>
								</div>

								{showBotton &&
								<div className='col m12'>
									<div className='row' style={{}}>
										<p style={{fontWeight:900, marging:0, padding:0, paddingTop:'10px'}}>
											Agregar nuevo departamento

										</p>
										<div className='col m12'>
											<div className='input-field'>
												<span>Nombre departamento</span>
												<input name='departamento' 
													value={deptInput.departamento} 
													onChange={(e) => setDepsInput({...deptInput, departamento: e.target.value})} 
													id='departamento' 
													type='text' 
													className='' />
											</div>
										</div>
										<div className='col m12'>
											<div className='input-field'>
											<span>Descripción departamento</span>
												<input name='descripcion' 
													value={deptInput.descripcion} 
													onChange={(e) => setDepsInput({...deptInput, descripcion:e.target.value})} 
													id='descripcion' 
													type='text' 
													className='validate' />
											</div>
										</div>
										<div className='row'>
											<div className='col m8'>
												<label style={{cursor:'pointer', fontSize:'12px', fontWeight:'900'}}>
													<div style={{width:'100%'}}>
														<img alt='img' src={foto?URL.createObjectURL(foto):'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/subirImg.jpg'} style={{margin:'0 auto', maxHeight:'100%', maxWidth:'100%', objectFit:'contain',textAlign:'left',}} />
													</div>
													<input  onChange={(e)=>{setFoto(e.target.files[0])}} type="file" style={{display:'none', height:'500px', width:'800px',}}/>
												</label>
											</div>
										</div>
										<div className='col m12'>
											<a href='#!' onClick={_pushDeps} className='btn waves-effect waves-light'>Agregar</a>
										</div>
										
									</div>
								</div>
								}
							</div>
						</div>
					
					{/*Agregar departamento*/}
					<div className='col m6' style={{border:'solid red!important'}}>
						
						{/*Agregar categoria*/}
						{data.departamento_id!==''&&
						<div className='col m12'>
							Seleccionar categorias
							<nav>
								<div className="nav-wrapper">
									<div className="input-field white">
										<input style={{marginLeft:50}} value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" placeholder='Categoria'/>
										<label className="label-icon" htmlFor="search"><i className="material-icons text-black black-text">search</i></label>
										<i className="material-icons">close</i>
									</div>
								</div>
							</nav>
							<div className='row'>
								<div style={{display:'flex', alignItems:'center'}}>
									<div className='col'>
										<div className='input-field'>
											<input name='categoria' value={catInput} onChange={(e) => setCatInput(e.target.value)} id='categoria' type='text' className='validate' />
											<label htmlFor='categoria'>Nueva Categoria</label>
										</div>
									</div>
									<div className='col'>
										<a href='#!' onClick={_pushCat} className='btn waves-effect waves-light'>Agregar</a>
									</div>
								</div>
								{categorias.filter((c) =>c.categoria.toLowerCase().includes(search)).map((c,i) =>
								<a href='#!' key={i} onClick={() => _catSelect(c)} className='waves-effect waves-light btn grey darken-4' style={{borderRadius:20, textTransform:'none', fontSize:12, letterSpacing:'none'}}><i className='material-icons right'>add</i>{c.categoria}</a>
								)}
							</div>
						</div>
						}
					</div> </div>
				</div>
				<div className='col s12' style={{marginBottom:50,}}>
					<div>Categorias seleccionadas:</div>
						{catSelect.map((c,i) =>
						<a key={i} href='#!' className='waves-effect waves-light btn' style={{borderRadius:20, textTransform:'none', fontSize:12, letterSpacing:'none'}}><i onClick={() => _catSelectDelete(c)} className='material-icons right'>clear</i>{c.categoria}</a>
						)}
				</div>
				<div className='custom-file-container' data-upload-id='uploadId' style={{marginBottom:50}}>
					<label style={{display:'none'}}>Fotos<a href='#!' className='custom-file-container__image-clear' title='Limpiar' >&times;</a ></label>
					<label className='custom-file-container__custom-file'>
						<span style={{border: '4px dashed rgba(0,0,0,0.35)', borderRadius:20, height:100, display:'flex', alignItems:'center', justifyContent:'center', position:'relative', fontSize:30}}>
							<input onChange={(e)=>setFotos([...fotos,e.target.files[0]])} type='file' className='custom-file-container__custom-file__custom-file-input' style={{cursor:'pointer',opacity:0,width:'100%', height:'100%', display:'block', position:'absolute'}} accept='*' multiple required/>
							Arrastra las imagenes de tu producto
						</span>
						<input type='hidden' name='MAX_FILE_SIZE' value='10485760' />
						<span className='custom-file-container__custom-file__custom-file-control' style={{display:'none'}}></span>
					</label>
				<div className='custom-file-container__image-preview'></div>
			</div>
			<button className='btn waves-effect waves-light'>Agregar producto</button>
		</form>
	</div>
	)
}

export default NewProduct
