import React, { useEffect,useState} from 'react';
import { axios } from '../../../../config';
import M from 'materialize-css';
import ListaProductos from './listaProductos';
import { Link } from 'react-router-dom';

const Ventas = () => {

	const [pedidos, setPedidos] = useState([]) 
	const [list, setList] = useState([])
	const [cupon, setCupon] = useState({})
	const [pago, setPago] = useState(0)
	const [search, setSearch] = useState('')

	useEffect(()=>{
		axios.get('/api/ventasonline').then((res)=>{
			setPedidos(res.data)
			console.log(res.data)
		}).catch(e=>console.log("error",e))
	},[])

	const _renderPayMethotImg = (metodo) =>{
		switch(metodo){
			case 'OxxoPay':
				return 'https://seeklogo.com/images/O/Oxxo-logo-0125669E96-seeklogo.com.png'
			case 'PayPal':
				return 'https://marcas-logos.net/wp-content/uploads/2019/12/PayPal-Logo.png';
			default:
				return 'https://icon-library.com/images/visa-master-icon/visa-master-icon-29.jpg';

		}
	}
	const _setEnviado = (id,idPedidoShop) =>{
		axios.post('/api/enviados',{id:id,idPedidoShop:idPedidoShop}).then((r)=>{
			setPedidos(pedidos.filter(i=> parseInt(i.source_object_reference.id) !== r.data.id))
			M.toast({html:'Actualizado! Pedido enviado!'})
		}).catch(e=>console.log('error',e))
	}

	return (
		<div className="col s12" style={{marginTop:50, marging:0, padding:0, width:'78vw'}}>
			{/*MODAL VER PEDIDO COMPLETO*/}
			<ListaProductos  list={list} cupon={cupon} />
			<nav style={{margin:50}}>
				<div className="nav-wrapper white">
					<form>
						<div className="input-field">
							<input value={search} onChange={(e)=>setSearch(e.target.value)} id="search" type="search" placeholder='Buscar por numero de pedido' required style={{marginLeft:50}}/>
							<label className="label-icon" htmlFor="search"><i className="material-icons black-text">search</i></label>
							<i className="material-icons">close</i>
						</div>
					</form>
				</div>
			</nav>

			{pedidos.length===0&&
			<div className='card-panel'>
				<center>
					<h4>Sin Pedidos</h4>
				</center>
			</div>
			}

			{pedidos.filter((pedido)=>pedido.id.toString().includes(search)).map((pedido)=>
				<div className='col s12' key={pedido.id} style={{margin:0, paddingBottom:'20px',}}>
					<div className='card-panel' style={{minHeight:400,margin:0, padding:0,}}>
						{pedido.source_object_reference&&
						<div className='row' style={{margin:0, padding:0,}}>
							<div className='col s12 m5' style={{margin:0, padding:0,}}>
								<div className='row' style={{margin:0, padding:0}}>
									<div className='col s12 m6' style={{margin:0, padding:0}}>
										<div className='row'  style={{margin:0, padding:'6px'}}>
											<h6 style={{fontWeight:700}}>Datos del cliente</h6>
											<div>
												<i className="material-icons large" style={{fontSize:45}}>person</i>
												<div><span style={{fontWeight:700,}}>Cliente:</span><br></br> {pedido.source_object_reference.envio.nombre}</div>
												<div style={{overflow:'hidden'}}><span style={{fontWeight:700,}}>E-mail:</span><br></br> {pedido.source_object_reference.envio.correo}</div>
												<div><span style={{fontWeight:700,}}>Telefono:</span><br></br> {pedido.source_object_reference.envio.telefono}</div>
											</div>
										</div>
									</div>
									<div className='col s12 m6' style={{borderLeft:'solid 1px #ededed', borderRight:'solid 1px #ededed'}}>
										<div className='row'>
											<div className='col s12'>
												{pedido.source_object_reference.envio.servicio==='Recolección'&&
													<>
														<h5>Recolección</h5>
														<Link to={`/entrega/${pedido.id}`} className='btn btn-waves btn-light btn-large black white-text'>Entrega</Link>
													</>
												}
												{pedido.source_object_reference.envio.servicio!=='Recolección'&&
													<>
													<h6 style={{fontWeight:700}}>Direccion de envio</h6>
													<div><span style={{fontWeight:700}}>Tipo de envio:</span> <br></br> {pedido.source_object_reference.envio.servicio}</div>
													<div><span style={{fontWeight:700}}>No de guia:</span> <br></br> {pedido.source_object_reference.envio.trackingNumber}</div>
													<div><span style={{fontWeight:700}}>Total envio:</span> <br></br> ${pedido.source_object_reference.envio.precio}</div>
													<div><span style={{fontWeight:700}}>Calle:</span> <br></br> {pedido.source_object_reference.envio.calle}</div>
													<div><span style={{fontWeight:700}}>NumExt:</span> {pedido.source_object_reference.envio.numExt} </div>
													<div><span style={{fontWeight:700}}>Codigo Postal:</span> {pedido.source_object_reference.envio.cp} </div>
													<div><span style={{fontWeight:700}}>Colonia:</span> {pedido.source_object_reference.envio.colonia} </div>
													<div><span style={{fontWeight:700}}>Ciudad:</span> {pedido.source_object_reference.envio.ciudad} </div>
													<div><span style={{fontWeight:700}}>Estado:</span> {pedido.source_object_reference.envio.estado} </div>
													</>
												}
											</div>
										</div>
									</div>
								</div>

								{pedido.source_object_reference.envio.servicio!=='Recolección'&&
								<div className='col s12 padding-10 center'>
									<button className='btn waves-effect' style={{width:'100%'}} onClick={()=>_setEnviado(pedido.id,pedido.source_object_reference.id)}> Listo para enviar</button>
								</div>
								}
							</div>

							<div className='col s12 m4' style={{margin:0, padding:0,}}>
								<div className='col s12' style={{margin:0, padding:0}}>
									<div className='row' style={{margin:0, padding:0, borderLeft:'solid 1px #ededed', borderRight:'solid 1px #ededed'}}>
										<div className='col s12 paddinb-10'>
											<div className='row' style={{margin:0, padding:0}}>
												<div className="col m6">
													<h5 className='light-green-text' style={{fontWeight:700}}>{pedido.source_object_reference.status}</h5>
												</div>
												<div className="col m6" style={{height:'70px', margin:'0 auto', padding:'0 auto', textAlign:'center'}}>
													<img src={_renderPayMethotImg(pedido.source_object_reference.metodo_pago)} 
													style={{maxHeight:'100%', maxWidth:'100%',objectFit:'scale-down',}}/>
												</div>
											</div>
											<div><span style={{fontWeight:700}}>Fecha de Pago:</span> {pedido.source_object_reference.fecha_pago}</div>
											{(pedido.source_object_reference.metodo_pago === 'PayPal')&&
												<div><span style={{fontWeight:700}}>Resumen:</span>{pedido.source_object_reference.response_pago.summary}</div>
											}
										</div>
										<div className='col s5'>
											<div style={{height:120,textAlign:'center',marginBottom:5}}>
											{pedido.source_object_reference.articulos&&
												<img src={pedido.source_object_reference.articulos[0].urls.split(',')[0]} style={{maxHeight:120}} />
											}
											</div>
										</div> 
										<div className='col s7'>
											{pedido.source_object_reference.articulos&&
												<>
											<div><span style={{fontWeight:700}}>Pedido No.</span> <br></br> {pedido.source_object_reference.articulos[0].pedido_id}</div>
											<div><span style={{fontWeight:700}}>Codigo:</span> <br></br> {pedido.source_object_reference.articulos[0].codigo}</div>
												</>
											}
											
										</div>
											{pedido.source_object_reference.articulos&&
										<div className='col s12'>
											<div><span style={{fontWeight:700}}>Articulo:</span> <br></br> {pedido.source_object_reference.articulos[0].nombre}</div>
											<div><span style={{fontWeight:700}}>Cantidad:</span> {pedido.source_object_reference.articulos[0].cantidad}</div>
											{(pedido.source_object_reference.cupon)&&
												<div>
													<span>Descuento: {pedido.source_object_reference.cupon.tipo}</span><span>{pedido.source_object_reference.cupon.descuento}</span>
												</div>
											}   
											<div className="row">
												<div className='col m6 red-text text-darken-2' style={{fontSize:18,fontWeight:'700'}}>Total pagado:<br></br> ${pedido.source_object_reference.total_pagado}</div>
												<div className="col m6" style={{paddingTop:'20px'}}>
													<button className='btn waves-effect modal-trigger' 
														data-target="modal1" 
														style={{width:'100%'}}
														onClick={()=>setList(pedido.source_object_reference.articulos)}
														>Ver Pedido Completo</button>
													</div>
											</div>
										</div>
											}
									   
									</div>
								</div>
							</div>
							<div className='col s12 m3' style={{textAlign:'center',margin:0, padding:0,}}>
								<div className='row' style={{margin:0, padding:0}}>
									<div className='col s12' style={{margin:0, padding:0}}>
										<div className='padding-10'>
										<iframe src={pedido.source_object_reference.envio.guia} style={{height:400,width:'100%'}} frameBorder="0"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div> 
						}
					</div>
				</div>
			)
			}

		</div>
	);
};

export default Ventas;

