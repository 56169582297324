import React, { useState, useEffect } from 'react'
import { axios } from '../../../../config'
import ImageGallery from 'react-image-gallery';
import { Link } from "react-router-dom"
import M from 'materialize-css'

const Catalogo = () => {
	const [view, setSwich] = useState(false)
	const [catalogo, setCatalogo] = useState([])
	const [papelera, setPapelera] = useState([])
	const [search, setSearch] = useState('')
	const [searchArchivados,setSearchArchivados] = useState('')
	const [searchSinCat, setSearchSinCat] = useState('')
	const [typing, setTyping] = useState(0)

	useEffect(()=>{
		axios.get('/api/catalogo')
		.then(r=>{
			setCatalogo(r.data)
			M.Tabs.init(document.querySelectorAll('.tabs'),{})
			
		})
		.catch(r=>alert(r))

		axios.get('api/getPapelera')
		.then(r=>{
			//console.log("papelera",r.data)
			setPapelera(r.data)
			M.Tooltip.init(document.querySelectorAll('.tooltipped'),{})
		})
		.catch(r=>alert(r))
	},[])

	const _changeView = () =>{
		setSwich(!view)
	}

	const _buildImageToGallery = (images) => {
		return images.length>0?images.map((i)=>({original:i.url})):[{original:'https://bitsofco.de/content/images/2018/12/broken-1.png'}]
	}

	/*
	   *
	   * Catalogo obj, String type = 'increment'|'decrement'|'value'
	   */
	const _updateStock = (obj, type) => {
		const newObj = {...obj, stock:type==='increment'?obj.stock+1:(type==='decrement'?obj.stock-1:type)}

		if(newObj.stock>=0){
			const updateCatalogo = catalogo.map((c) =>c.id===obj.id?newObj:c)
			setCatalogo(updateCatalogo)

			clearTimeout(typing)
			setTyping(setTimeout(() => {
				axios.post('/api/updateStock',{codigo:newObj.codigo, stock:newObj.stock})
				.then(r=>{
					M.toast({html:'Actualizado!'})
				})
			.catch(r=>alert(r))
			},1000))
		}
	}
	
	const _restaurar = (id) =>{
		axios.put(`/api/updatePapelera/${id}`)
		.then((r) => {
			M.toast({html: 'Producto Restaurado'})
			setPapelera(papelera.filter(p =>p.id !==id))

			axios.get('/api/catalogo')
			.then(r=>{
				setCatalogo(r.data)
			})
			.catch(r=>alert(r))
		})
		.catch(r=>alert(r))
	}
	/*
	   *
	   * Catalogo obj
	   */
	const _updateCatalogo = (obj,checked) =>{
		const newCatalogo = catalogo.map(c=>c.id===obj.id?{...obj,visible:checked}:c)
		setCatalogo(newCatalogo)
		axios.post('/api/updateVisible',{id:obj.id,visible:checked})
		.then((r) => {
			M.toast({html:'Actualizado!'})
		})
		.catch(r=>alert(r))	
	}


	return (
		<div className="col s12" style={{marginTop:"50px"}}>
			<ul id="tabs-swipe-demo" className="tabs ul-tabs">
				<li className="tab col s2"><a className="active" href="#catalogo-tab">Catálogo</a></li>
				<li className="tab col s2"><a href="#papelera-tab">Archivados</a></li>
				<li className="tab col s3"><a href="#productos-otros-tab">Productos sin departamento</a></li>
			</ul>
			<div id="catalogo-tab" className='card-panel'>
				<div className="row m-p-0">
				{!view &&
					<div className="col m12 m-p-0">  
						<i className="small material-icons right" 
						onClick={_changeView}
						style={{cursor:'pointer'}}>tock</i> 
					</div>
				}
				{view &&
					<div className="col m12 m-p-0"> 
						
						<i className="small material-icons right" 
						onClick={_changeView}
						style={{cursor:'pointer'}}>apps</i> 
					</div>
				}
				</div>
				
				<h4>Catálogo</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white row">
								<div className='col s1 m1' style={{margin:0, padding:0, textAlign:'center'}}> 
									<label className="label-icon" htmlFor="search"><i className="material-icons" style={{color: 'black',}}>search</i></label>
								</div>
								<div className='col s10 m10' style={{margin:0, padding:0,}}> 
									<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" required placeholder='Código, Categoría, Departamento, Nombre'/>
								</div>
								<div className='col s1 m1' style={{margin:0, padding:0, textAlign:'center',}}> <i className="material-icons">close</i> </div>
							</div>
						</form>
					</div>
				</nav>
			{!view?
				<div className="row">
							<table id="table-catalogo">
								<thead>
								<tr>
									<th></th>
									<th>Clave</th>
									<th>Nombre</th>
									<th>Visible</th>
									<th>Departamento</th>
									<th>Categoría</th>
									<th>Descripción</th>
									<th>Precio</th>
									<th></th>
								</tr>
								</thead>

								<tbody>
								{catalogo.filter(c=>c.nombre.toLowerCase().includes(search.toLowerCase()) || c.codigo.includes(search)||c.categoria.toLowerCase().includes(search.toLowerCase()) || c.departamento.toLowerCase().includes(search.toLowerCase())).slice(0,50).map(c=>
								
									<tr key={c.id}>
										<td style={{width:'100px'}}>
											<ImageGallery 
												useBrowserFullscreen={true} 
												items={_buildImageToGallery(c.imagenes)} 
												lazyLoad={true} autoPlay={true} 
												showPlayButton={false} 
												showThumbnails={false} 
												showNav={false}
												showFullscreenButton={false}
												style={{border:'solid purple'}} />
										</td>
										<td> {c.codigo} </td>
										<td> {c.nombre} </td>
										<td>
											<div className="switch">
												<label>
													<input type="checkbox" checked={c.visible} onChange={(e)=>_updateCatalogo(c,e.target.checked)}/>
													<span className="lever"></span>
												</label>
											</div> 
										</td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td> {c.descripcion} </td>
										<td> 
											<p style={{color:'#000000', textAlign:'center', fontSize:'14px', fontWeight:'900!important', margin:0, padding:'0 0 5px 0'}}>$ {c.precio}</p>
											<p className="m-p-0" style={{textAlign:'center', fontWeight:'900!important'}}>EXISTENCIAS</p>
											<p className="m-p-0" style={{textAlign:'center'}}>
												<a href='#!' onClick={()=>_updateStock(c, 'decrement')} className='btn waves-effect waves-light m-p-0' 
													style={{width:15, height:15, padding:2, lineHeight:'1', fontWeight:'bold', fontSize:12}}>-</a>
												<input value={c.stock} onChange={(e)=>_updateStock(c, e.target.value)} type='number' 
													style={{height:20,width:'20%', border:0, margin:'0px 5px 0px 5px', fontSize:12, textAlign:'center',}}/>
												<a href='#!' onClick={()=>_updateStock(c, 'increment')} className='btn waves-effect waves-light m-p-0' 
													style={{width:15, height:15, padding:2, lineHeight:'1', fontWeight:'bold', fontSize:12}}>+</a>
											</p>
											{c.stock===0&&
											<p className="m-p-0" style={{color:'red', fontWeight:'bold', fontSize:'12px',textAlign:'center'}}>Agotado</p>
											} 
										</td>
										<td>
											<Link to={`/catalogo/producto/${c.id}`} className='black-text'>
												<i className="material-icons">edit</i>
											</Link>
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
				:
				<div className="row">
				{catalogo.filter(c=>c.nombre.toLowerCase().includes(search.toLowerCase()) || c.codigo.includes(search)||c.categoria.toLowerCase().includes(search.toLowerCase()) || c.departamento.toLowerCase().includes(search.toLowerCase())).slice(0,50).map(c=>
					<div key={c.codigo} className="col m6">
						<div className="card-panel" style={{position:'relative'}}>
							<Link to={`/catalogo/producto/${c.id}`} className='black-text' style={{position:'absolute', right:10, top:10}}>
								<i className="material-icons">edit</i>
							</Link>
						<div className='row'>
							<div className="col m5" id="slider2">
								<ImageGallery useBrowserFullscreen={true} 
								items={_buildImageToGallery(c.imagenes)} 
								lazyLoad={true} autoPlay={true} 
								showPlayButton={false}
								showThumbnails={false} 
								showNav={false}
							/>
							</div>

							<div className="col m7">
								<div style={{height:200, overflowY:'auto'}}>
									<div className='black-text'>{c.codigo}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Nombre:</b> {c.nombre}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Descripción:</b> {c.descripcion}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Categorías:</b> {c.categoria}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Departamento:</b> {c.departamento}</div>
									<div className='black-text'><b style={{fontWeight:'bold', fontSize:'12px'}}>Precio:</b> {c.precio}</div>
								</div>
								<div className='black-text' style={{display:'flex', alignItems:'center', height:70}}>Stock: 
									<a href='#!' onClick={()=>_updateStock(c, 'decrement')} className='btn waves-effect waves-light' style={{width:25, height:25, padding:3, lineHeight:'18px', fontWeight:'bold', fontSize:28}}>-</a>
									<input value={c.stock} onChange={(e)=>_updateStock(c, e.target.value)} type='number' style={{height:30,width:46, border:0, margin:'0px 5px 0px 5px', fontSize:28, textAlign:'center'}}/>
									<a href='#!' onClick={()=>_updateStock(c, 'increment')} className='btn waves-effect waves-light' style={{width:25, height:25, padding:3, lineHeight:'18px', fontWeight:'bold', fontSize:28}}>+</a>
								{c.stock===0&&
								<h4 style={{color:'red', fontWeight:'bold', fontSize:'16px', marginLeft:20}}>Agotado</h4>
								}
								</div>
							</div>
						</div>
						</div>
					</div>
				)}
			
				</div>
			}
			</div>

			<div id="papelera-tab" className='card-panel'>
				<h4>Archivados</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white">
								<input value={searchArchivados} onChange={e=>setSearchArchivados(e.target.value)} id="searchArch" type="search" required placeholder='Código, Categoría, Departamento, Nombre'/>
								<label className="label-icon" htmlFor="searchArch"><i className="material-icons" style={{color: 'black'}}>search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				<div className="row">
				
							<table id="table-catalogo">
								<thead>
								<tr>
									<th></th>
									<th style={{textAlign:"left"}}>Clave</th>
									<th style={{textAlign:"left"}}>Nombre</th>
									<th style={{textAlign:"left"}}>Departamento</th>
									<th style={{textAlign:"left"}}>Categoría</th>
									<th></th>
								
								</tr>
								</thead>

								<tbody>
								{papelera.filter(c=>c.nombre.toLowerCase().includes(searchArchivados.toLowerCase()) || c.codigo.includes(searchArchivados)||c.categoria.toLowerCase().includes(searchArchivados.toLowerCase()) || c.departamento.toLowerCase().includes(searchArchivados.toLowerCase())).slice(0,50).map((c,i)=>
									<tr key={i}>
										<td style={{width:'100px'}}>
											<ImageGallery
												useBrowserFullscreen={true}
												items={_buildImageToGallery(c.imagenes)}
												lazyLoad={true}
												autoPlay={true}
												showPlayButton={false}
												showThumbnails={false}
												showFullscreenButton={false}
												showNav={false}/>
										</td>
										<td> {c.codigo} </td>
										<td> {c.nombre} </td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td>
											<div onClick={()=>_restaurar(c.id)} className='black-text pointer tooltipped' data-position="top" data-tooltip="Restaurar">
												<i className="material-icons">unarchive</i>
											</div>
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
			</div>
			<div id="productos-otros-tab" className='card-panel'>
				<h4>Productos sin departamento</h4>
				<nav>
					<div className="nav-wrapper">
						<form>
							<div className="input-field white">
								<input value={searchSinCat} onChange={e=>setSearchSinCat(e.target.value)} id="searchCat" type="search" required placeholder='Código, Categoría, Departamento, Nombre'/>
								<label className="label-icon" htmlFor="search"><i className="material-icons" style={{color: 'black'}}>search</i></label>
								<i className="material-icons">close</i>
							</div>
						</form>
					</div>
				</nav>
				<div className="row">
							<table id="table-catalogo">
								<thead>
								<tr>
									<th></th>
									<th>Clave</th>
									<th>Nombre</th>
									<th>Visible</th>
									<th>Departamento</th>
									<th>Categoría</th>
									<th>Descripción</th>
									<th>Precio</th>
									<th></th>
								</tr>
								</thead>

								<tbody>
								{catalogo.filter(c=>(c.nombre.toLowerCase().includes(searchSinCat.toLowerCase()) || c.codigo.includes(searchSinCat)||c.categoria.toLowerCase().includes(searchSinCat.toLowerCase()))&&c.departamento.toLowerCase().includes('otros')).slice(0,50).map((c,i)=>
									<tr key={i}>
										<td style={{width:'100px'}}>
											<ImageGallery 
												useBrowserFullscreen={true} 
												items={_buildImageToGallery(c.imagenes)} 
												lazyLoad={true} autoPlay={true} 
												showPlayButton={false} 
												showThumbnails={false} 
												showNav={false}
												showFullscreenButton={false}
												style={{border:'solid purple'}} />
										</td>
										<td> {c.codigo} </td>
										<td> {c.nombre} </td>
										<td>
											<div className="switch">
												<label>
													<input type="checkbox" checked={c.visible} onChange={(e)=>_updateCatalogo(c,e.target.checked)}/>
													<span className="lever"></span>
												</label>
											</div> 
										</td>
										<td> {c.departamento} </td>
										<td> {c.categoria} </td>
										<td> {c.descripcion} </td>
										<td> 
											<p className="m-p-0" style={{textAlign:'center'}}>{c.precio}</p>
											<p className="m-p-0"  style={{textAlign:'center'}}>
												<a href='#!' onClick={()=>_updateStock(c, 'decrement')} className='btn waves-effect waves-light m-p-0' 
													style={{width:15, height:15, padding:2, lineHeight:'1', fontWeight:'bold', fontSize:12}}>-</a>
												<input value={c.stock} onChange={(e)=>_updateStock(c, e.target.value)} type='number' 
													style={{height:20,width:'20%', border:0, margin:'0px 5px 0px 5px', fontSize:12, textAlign:'center'}}/>
												<a href='#!' onClick={()=>_updateStock(c, 'increment')} className='btn waves-effect waves-light m-p-0' 
													style={{width:15, height:15, padding:2, lineHeight:'1', fontWeight:'bold', fontSize:12}}>+</a>
											</p>
											{c.stock===0&&
											<p className="m-p-0" style={{color:'red', fontWeight:'bold', fontSize:'12px',textAlign:'center'}}>Agotado</p>
											} 
										</td>
										<td>
											<Link to={`/catalogo/producto/${c.id}`} className='black-text'>
												<i className="material-icons">edit</i>
											</Link>
										</td>
									</tr>
								)}
								</tbody>
							</table>
				</div>
			</div>
		</div>
	)
}

export default Catalogo
