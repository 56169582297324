import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { axios } from '../../../../config'

const Entrega = () =>{

	const [data, setData] = useState({nombre:''})
	const {pedido_id} = useParams()
	const history = useHistory()

	useEffect(()=>{

		var canvas = document.querySelector('canvas')
		var context = canvas.getContext("2d");
		var lastx;
		var lasty;

		context.strokeStyle = "#000000";
		context.lineCap = 'round';
		context.lineJoin = 'round';
		context.lineWidth = 5;

		canvas.ontouchstart = function(event){
				event.preventDefault();
				var rect = canvas.getBoundingClientRect();
				lastx = event.touches[0].clientX - rect.left;
				lasty = event.touches[0].clientY - rect.top;
				context.beginPath();
				context.fillStyle = "#000000";
				context.arc(lastx,lasty,1,0,Math.PI*2,true);
				context.fill();
				context.stroke();
				context.closePath();
		}

		canvas.ontouchmove = function(event){
				event.preventDefault();
				var rect = canvas.getBoundingClientRect();
				var newx = event.touches[0].clientX-rect.left;
				var newy = event.touches[0].clientY - rect.top;
				context.beginPath();
				context.moveTo(lastx, lasty);
				context.lineTo(newx, newy);
				context.stroke();
				context.closePath();
				lastx = newx;
				lasty = newy;
		}

	},[])
	
	
	const _clean=()=>{
		var canvas = document.querySelector('canvas');
		var context = canvas.getContext('2d');
		context.fillStyle='white'
		context.fillRect(0, 0, canvas.width, canvas.height);
	}

	const _confirmar = (e) => {
		e.preventDefault()

		var canvas = document.querySelector('canvas');
		const firma=canvas.toDataURL()
		const dataF = {...data, firma, pedido_id}
		console.log(dataF)
		axios.post('/api/entregar', dataF)
		.then(r=>{
			console.log(r)
			history.push('/ventasonline')	
		})
		.catch(e=>alert(e))

	}

	return (
		<div className='card-panel'>
			<h4>Entrega</h4>
			<div className="row">
				<form onSubmit={_confirmar}>
					<div className="row">
						<div className="input-field col">
							<input value={data.nombre} onChange={(e)=>setData({...data, nombre:e.target.value})} id="nombre" type="text" className="validate" required/>
							<label htmlFor="nombre">Nombre de quién recoge</label>
						</div>
					</div>
					<div className='row'>
						<h5>Firma</h5>
						<a className='btn waves-effect waves-light' onClick={_clean}>volver a intentar</a>
						<canvas width='400' height='400' style={{boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', background:'white'}}/>
					</div>
					<button className='btn waves-effect waves-light'>Confirmar</button>
				</form>
			</div>
		</div>
	)
}

export default Entrega
