import React, { useEffect, useState } from 'react';
import { axios } from '../../../../config'
import M from 'materialize-css'
import { useParams, Link , useHistory} from 'react-router-dom'
import JoditEditor from "jodit-react";
import AsyncCreatableSelect from 'react-select/async-creatable';


const NewProduct = () => {

	const {catalogo_id} = useParams()
	const [data, setData] = useState({codigo:'', nombre:'', modelo:'', talla:'', descripcion:'',descripcion_general:'', peso_bruto:'', peso_neto:'', precio:'', minimo_compra:'', departamento_id:'',visible:true, imagenes:[], stock:{}})
	const [categorias, setCategorias] = useState([])
	const [catSelect, setCatSelect] = useState([])
	const [departamentos, setDepartamentos] = useState([])
	const [catInput, setCatInput] = useState('')
	const [loader, setLoader] = useState(false)
	const [search, setSearch] = useState('')
	const history = useHistory()
	const [catalogo, setCatalogo] = useState([])
	const [inputModelos, setInputModelos] = useState([])

	useEffect(() => {

		axios.get('/api/categoria')
		.then((r) => {
			setCategorias(r.data)
		})
		.catch(r=>alert(r))

		axios.get('/api/departamento')
		.then((r) => {
			setDepartamentos(r.data)
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
		.catch(r=>alert(r))

		axios.get(`/api/catalogo/${catalogo_id}`)
		.then((r) => {
			setData(r.data)
			setCatSelect(r.data.categorias)
			M.FormSelect.init(document.querySelectorAll('select'), {});
			M.updateTextFields()
			M.textareaAutoResize(document.querySelector('#textarea1'));
			M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});
		})
		.catch((r)=>alert(r))
	}, [catalogo_id])

	const _catSelect = (obj) => {
		if(!catSelect.some((c)=>c.id===obj.id)){
			setCategorias(categorias.filter((c)=>c.id!==obj.id))
			setCatSelect([...catSelect, obj])

			axios.post('/api/catalogo_categoria',{catalogo_id, categoria:obj, type:'attach'})
			.then((r) => {
				M.toast({html: 'Categoria actualizada!'})
			})
			.catch((r)=>alert(r))
		}
	}

	const _catSelectDelete = (obj) => {

		setCatSelect(catSelect.filter((c)=>c.id!==obj.id))
		setCategorias([...categorias, obj])

			axios.post('/api/catalogo_categoria',{catalogo_id, categoria:obj, type:'detach'})
			.then((r) => {
				M.toast({html: 'Categoria actualizada!'})
			})
			.catch((r)=>alert(r))
	}

	const _pushCat = () => {
		axios.post('/api/categoria',{categoria:catInput,departamento_id:data.departamento_id})
		.then((r) => {
			setCatSelect([...catSelect, r.data])
			setCatInput('')
			axios.post('/api/catalogo_categoria',{catalogo_id, categoria:r.data, type:'attach'})
			.then((r) => {
				M.toast({html: 'Categoria actualizada!'})
			})
			.catch((r)=>alert(r))
		})
		.catch(r=>alert(r))
	}

	const _pushData = (e) => {
		e.preventDefault()
		console.log(catSelect)
		if(catSelect.length===0){
			alert('selecciona categorias')
		}
		else{
			const dataF = new FormData()
			dataF.append('_method', 'PUT')
			dataF.append('data', JSON.stringify({...data, categorias: catSelect.map((c) =>c.id ), modelo:(data.modelo&&data.modelo.value)?data.modelo.value:data.modelo}))
	
			setLoader(true)
			axios.post('/api/catalogo',dataF)
			.then((r) => {
				setLoader(false)
				setData(r.data)
				setCatSelect(r.data.categorias)
				M.toast({html: 'Actualizado!'})
				M.FormSelect.init(document.querySelectorAll('select'), {});
				M.textareaAutoResize(document.querySelector('#textarea1'));
				M.updateTextFields()
			})
			.catch(r=>{
				setLoader(false)
				alert(r)
			})
		}
	}

	const _imgDestroya = (obj) => {
		axios.delete(`/api/imagen/${obj.id}`)
		.then((r) => {
			setData({...data, imagenes:data.imagenes.filter((i) => i.id!==obj.id)})
		})
		.catch((r) =>alert(r))
	}

	const _fileUpload = (e) => {
		const dataF = new FormData()
		dataF.append('file', e.target.files[0])
		dataF.append('catalogo_id', catalogo_id)
		dataF.append('codigo', data.codigo)

		axios.post('/api/imagen', dataF)
		.then((r) => {
			setData({...data, imagenes:[...data.imagenes, r.data]})
			M.toast({html: 'Archivo subido!'})
			M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});
		})
		.catch((r) =>alert(r))

	}

	const _softProductDelete = (e) =>{
		e.preventDefault();
		if(window.confirm("¿Estas seguro de archivar este producto?")){
			axios.put(`/api/updatePapelera/${catalogo_id}`)
			.then((r) => {
				M.toast({html: 'Producto Archivado'});
				setTimeout(function(){ history.push('/catalogo') }, 2000)
			})
			.catch(r=>alert(r))
		}
	}

	const _dosDecimales = (n) => {
		let t=n.toString();
		let regex=/(\d*.\d{0,2})/;
		if(t.length===0) return 0;
		return t.match(regex)[0];
	}

	const _updateStock = (codigo, stock) => {
		if(stock>=0){
			axios.post('/api/updateStock',{codigo:codigo, stock:stock})
				.then(r=>{
					M.toast({html:'Actualizado!'})
				})
			.catch(r=>alert(r))
		}
	}

	const promiseOptions = (search) => {

		console.log('hola')
		console.log(search)

		return new Promise((resolve, reject) => {

		if(search.length==1){
			axios.post('/api/modelos', {search})
			.then(r=>{
				setInputModelos(r.data)
				resolve(r.data)
				console.log('paso1')
			})
			.catch(r=>alert(r))
		}else{
			setTimeout(() => {
				resolve(inputModelos.filter(c=>c.label.includes(search.toUpperCase())));
			}, 300);
		}

		});

	}
	

	if(loader)
	return (
		<div className='progress'>
			<div className='indeterminate'></div>
		</div>
	)

	return(
		<div className='card-panel'>
			
			<h4>Editar producto</h4>
			<form onSubmit={_pushData}>
				<div className='row'>
					<div className='col m6'>
						<div className='col m12' onSubmit={(e)=>e.preventDefault()}>
							Activar / Desactivar producto
							<div className="switch" style={{margin:'0', paddingBottom:'20px', textAlign:'left'}}>
								<label style={{fontWeight:600,}}>
									Producto NO visible
									<input type="checkbox" checked={data.visible} 
									onChange={(e)=>setData({...data, visible:e.target.checked})}
									style={{fontSize:'12px',}} />
									<span className="lever"></span>
									Producto visible
								</label>
							</div> 
			
							<div className='row'>
								<div className='input-field col s12'>
									<input name='codigo' value={data.codigo} onChange={(e) => setData({...data, codigo:e.target.value})} id='codigo' type='text' required/>
									<label htmlFor='codigo'>Código</label>
								</div>
								<div className='input-field col s12'>
									<input name='nombre' value={data.nombre} onChange={(e) => setData({...data, nombre:e.target.value})} id='nombre' type='text' required/>
									<label htmlFor='nombre'>Nombre</label>
								</div>
							<label>Modelo(Opcional)</label>
							<div style={{fontSize:10, color:'red'}}>Nota: el modelo es importante para casos con productos de unico modelo, por ejemplo productos unicos con tallas diferentes.</div>
							<div className='input-field col s12'>
								<AsyncCreatableSelect formatCreateLabel={(e)=>'Nuevo Modelo: '+e.toUpperCase()} value={(data.modelo&&data.modelo.value)?data.modelo:{value:data.modelo, label:data.modelo}} onChange={(e)=>setData({...data, modelo:{value:e.value.toUpperCase(), label:e.label.toUpperCase()}})} defaultOptions loadOptions={promiseOptions} loadingMessage={()=>'Cargando info...'}/>
							</div>
							<div className='input-field col s12'>
								<input name='talla' value={data.talla} onChange={(e) => setData({...data, talla:e.target.value})} id='talla' type='text' />
								<label htmlFor='talla'>Talla (opcional)</label>
							</div>


							</div>
						</div>
					</div>
					<div className='col m6'>
						<div className='input-field col s12'>
							<textarea name='descripcion' value={data.descripcion||''} onChange={(e) => setData({...data, descripcion:e.target.value})}  id='textarea1' className='materialize-textarea' style={{minHeight:200, border:'solid 1px grey'}} required />
							<label htmlFor='textarea1'>Descripción</label>
						</div>
					</div>
					<div className='row'>
						<div className='input-field col s12'>
							<div>Descripción general:</div>
							<JoditEditor value={data.descripcion_general} config={{ readonly: false,askBeforePasteHTML:false }} tabIndex={1} onBlur={newContent => setData({...data, descripcion_general:newContent})} />
						</div>
					</div>
					<div className='row'>
						<div className='col m4'>
							<div className='input-field col s12'>
								Existencias<span style={{color:'#000000', fontSize:'12px', fontWeight:900}}> Nota:Numero de articulos </span> <br></br>
                                
								<input value={data.stock.piezas} 
								onChange={(e)=>{_updateStock(data.codigo, e.target.value);setData({...data,stock:{piezas:e.target.value}})}} 
								type='number' />
							</div>
						</div>
						<div className='col m4'>
							<div className='input-field col s12'>
								Peso bruto en gramos.  <span style={{color:'#000000', fontSize:'12px', fontWeight:900}}> Nota:Decimales no son permitidos </span> <br></br>
                                <input name='peso_bruto' 
                                value={data.peso_bruto} onChange={(e) => setData({...data, peso_bruto:e.target.value.replace(/\D/g, '')})}  
                                id='peso-bruto' type='number' required/>
							</div>
						</div>
						<div className='col m4'>
							<div className='input-field col s12'>
                                Peso neto en gramos.  <span style={{color:'#000000', fontSize:'12px', fontWeight:900}}> Nota:Decimales no son permitidos</span> <br></br>
								<input name='peso_neto' value={parseInt(data.peso_neto)} onChange={(e) => setData({...data, peso_neto:e.target.value.replace(/\D/g, '')})} id='peso-neto' type='number' required/>
							</div>
						</div>
						<div className='col m6'>
							<div className='input-field col s12'>
								<input name='precio' value={data.precio} onChange={(e) => setData({...data, precio:_dosDecimales(e.target.value)})} id='precio' type='number' required/>
								<label htmlFor='precio'>Precio</label>
							</div>
						</div>
						<div className='col m6'>
							<div className='input-field col s12'>
								<input name='minimo_compra' value={data.minimo_compra} onChange={(e) => setData({...data, minimo_compra:e.target.value.replace(/\D/g, '')})} id='minimo_compra' type='number' required/>
								<label htmlFor='minimo_compra'>Minimo de compra</label>
							</div>
						</div>
					</div>
					<div className='col m12'>
					
						<div className='col m6'>
							Seleccionar departamento
							<div className='input-fieldi col s12'>
								<select name='departamento' value={data.departamento_id} 
									onChange={(e) => {
										setData({...data, departamento_id:e.target.value});
										setCatSelect([]);
										}
										} required>
									<option value='' disabled>Seleccionar departamento</option>
									{departamentos.map((d,i) => <option key={i} value={d.id}>{d.departamento}</option>)}
								</select>
								<label>Departamento</label>
							</div>

							<div className='row' style={{marginBottom:50}}>
								<div>Categorías seleccionadas:</div>
								{catSelect.map((c,i)=><a key={i} href='#!' className='waves-effect waves-light btn' style={{borderRadius:20, textTransform:'none', margin:'3px 2px', fontSize:12, letterSpacing:'none'}}><i onClick={() => _catSelectDelete(c)} className='material-icons right'>clear</i>{c.categoria}</a>)}
							</div>
						</div>
						<div className='col m6'>
							Seleccionar categorías
							<nav>
								<div className="nav-wrapper row">
									<div className='col s1 m1 white' style={{margin:0, padding:0, textAlign:'center',}}> <label className="label-icon" htmlFor="search"><i className="material-icons black-text">search</i></label>
									</div>
									<div className="col s10 m10 input-field white"  style={{margin:0, padding:0,}}>
										<input value={search} onChange={e=>setSearch(e.target.value)} id="search" type="search" placeholder='Categoria'/>
										</div>	
									<div className='col s1 m1' style={{margin:0, padding:0, textAlign:'center',}}> <i className="material-icons">close</i> </div>
									
								</div>
							</nav>
							<div className='row'>
								<div style={{display:'flex', alignItems:'center',}}>
									<div className='col'>
										<div className='input-field'>
											<input name='categoria' value={catInput} onChange={(e) => setCatInput(e.target.value)} id='categoria' type='text' className='validate' />
											<label htmlFor='categoria'>Nueva categoría</label>
										</div>
									</div>
									<div className='col'>
										<a href='#!' onClick={_pushCat} className='btn waves-effect waves-light'>Agregar</a>
									</div>
								</div>
								{categorias.filter(c=>c.categoria.toLowerCase().includes(search)).map((c,i) =>
								<a href='#!' key={i} onClick={() => _catSelect(c)} className='waves-effect waves-light btn grey darken-4' style={{borderRadius:20, textTransform:'none', fontSize:12, letterSpacing:'none', margin:'3px 2px'}}>
									<i className='material-icons right'>add</i>{c.categoria}
								</a>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className='row' style={{display:'flex',flexWrap: 'wrap',alignItems:'center'}}>
					{data.imagenes.map((img,i)=>
						<div key={i} style={{position:'relative'}}>
							<img alt={data.codigo} className='materialboxed' data-caption={data.descripcion} src={img.url} style={{width:200, margin:20, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.5)'}} />
							<a href='#!' onClick={() =>_imgDestroya(img)} className='black-text' style={{position:'absolute', top:5, right:9}}><i className="material-icons">clear</i></a>
						</div>
					)}
					<div style={{height:'140px', width:'140px',}}>
						<div style={{height:'56px', marginLeft:'20px',width:'50px',}}>
							<label style={{background:'white', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',borderRadius:'100%', padding:10, cursor:'pointer', display:'flex', alignItems:'center'}}>
								<i className="material-icons black-text" style={{paddingLeft:'4px'}}>add</i>
								<input type="file" style={{display:'none'}}  onChange={_fileUpload}/>
							</label>
						</div>
						<div style={{height:'100px',  width:'90px',}}>
								<img alt='img' src='https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/subirImg.jpg' 
								style={{margin:'0 auto', height:'100%', width:'100%', objectFit:'cover', }} />
						</div>
						
					</div>
				</div>
				<div style={{position:'relative'}}>
					<button className='btn waves-effect waves-light'>Actualizar Producto</button>
					<button  onClick={(e)=>_softProductDelete(e)} className='btn waves-effect waves-light red margin-left-10'>Archivar Producto</button>
					<Link to='/catalogo' className='btn waves-effect waves-light bold' style={{right:10, position:'absolute'}}>Regresar</Link>
				</div>
			</form>
		</div>
	)
}

export default NewProduct
