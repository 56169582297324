import React, { useState, useEffect } from 'react'
import { axios } from '../../../../config'
import M from 'materialize-css'
import FileUploadWithPreview from 'file-upload-with-preview';

const NewDepto = () => {

	const [data, setData] = useState({departamento:'', descripcion:''})
	const [foto, setFoto] = useState(false)
	const [loader, setLoader] = useState(false)

	const _pushData = (e) => {
		e.preventDefault()
		const dataDep = new FormData()
		dataDep.append('data', JSON.stringify(data))
		dataDep.append('files', foto);
		
		axios.post('/api/departamento',dataDep)
		.then((r) => {
			setLoader(false)
			setData({departamento:'', descripcion:''})
			M.toast({html: 'Nuevo departamento!'})			
			setFoto(false)
		})
		.catch(r=>{
			if(r.response.status===400)
			alert(r.response.data.message)
			else alert(r)
			setLoader(false)
		})
		
	}
	
	if(loader)
	return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)
	return (
		<div className='card-panel'>
			<h4>Alta de departamento</h4>
			<form onSubmit={_pushData}>
			<div onSubmit={(e)=>e.preventDefault()}>
				<div className='row'>
					<div className='col m8 m-p-0'>
						<div className='row m-p-0'>
							<div className='input-field col s12'>
								<input name='departamento' id='departamento' value={data.departamento} 
								onChange={(e) => setData({...data, departamento:e.target.value})} type='text' required/>
								<label htmlFor='departamento'>Nombre del departamento</label>
							</div>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col m8 m-p-0'>
						<div className='row m-p-0'>
							<div className='input-field col s12'>
								<textarea name='descripcion' id='descripcion' value={data.descripcion} onChange={(e) => setData({...data, descripcion:e.target.value})}  
								className='materialize-textarea' style={{minHeight:200, border:'solid 1px grey'}} required />
								<label htmlFor='descripcion'>Descripción corta</label>
							</div>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col m8'>
						<label style={{cursor:'pointer', fontSize:'12px', fontWeight:'900'}}>
							<div style={{width:'100%'}}>
								<img alt='img' src={foto?URL.createObjectURL(foto):'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/subirImg.jpg'} style={{margin:'0 auto', maxHeight:'100%', maxWidth:'100%', objectFit:'contain',textAlign:'left',}} />
							</div>
							<input  onChange={(e)=>{setFoto(e.target.files[0])}} type="file" style={{display:'none', height:'500px', width:'800px',}}/>
						</label>
					</div>
				</div>
				<button className='btn waves-effect waves-light'>Agregar Departamento</button>
			</div>
			</form>
		</div>
	)
}

export default NewDepto
